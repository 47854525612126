import React from "react";
import { Heading, Subhead } from "rebass";
import CallToAction from "../UI/CallToAction";
import logo from "../assets/logo_high_resolution_white.png";
import appstore from "../assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.png";
import playstore from "../assets/google-play-badge.png"
// import Mailchimp from "../UI/Mailchimp";

const MainPage = props => {
  // const url =
  //   "https://app.us5.list-manage.com/subscribe/post?u=755d5319d7343bc5b4796329e&amp;id=34a61fff0b";

  return (
    // <Fragment>
    <div style={{ height: "85vh", backgroundColor: "#F9423A", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      <div style={{ paddingLeft: "1%" }}>
        <img src={logo} alt="NoGame" height="250" />
      </div>
      <Heading>{/* NoGame */}</Heading>
      <Subhead style={{ color: "white", marginTop: "5vh" }}>Your digital dating coach</Subhead>
      <div style={{ margin: "1vh" }}></div>
      <CallToAction href="/" mt={3}>
        <a href="https://apps.apple.com/de/app/nogame/id1488174325">
          <img src={appstore} alt="AppStore" height="40" style={{ marginRight: 20 }} />
        </a>
        <a href="https://play.google.com/store/apps/details?id=app.nogame">
          <img src={playstore} alt="Play Store" height="40" />
        </a>
      </CallToAction>
      {/* <ScrollDownIndicator /> */}
    </div>
    // <Section
    //   bg="#F9423A"
    //   width={1}
    //   heading="Stay tuned for the first release"
    //   color="white"
    //   // subhead="maybe this will help"
    // >
    //   <Mailchimp
    //     action={url}
    //     fields={[
    //       {
    //         name: "EMAIL",
    //         placeholder: "",
    //         type: "email",
    //         required: true
    //       }
    //     ]}
    //   />
    // </Section> 
    // </Fragment>
  );
};

export default MainPage;
