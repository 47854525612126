import styled from 'styled-components'
import { CallToAction } from 'react-landing-page'

const CustomCallToAction = styled(CallToAction)`
  background-color: #F9423A;
  &:hover {
    background-color: "red";
  }
`

export default CustomCallToAction
