import React from "react";
import { Provider, Relative, Absolute, Flex, NavLink, Small } from "rebass";
import theme from "./UI/theme";
import { BrowserRouter, Link, Route } from "react-router-dom";
import styled from "styled-components";

import MainPage from "./MainPage/MainPage";
import PrivacyScreen from "./PrivacyScreen/PrivacyScreen";
import SupportScreen from "./SupportScreen/SupportScreen";


const App = props => {
  // const url =
  //   "https://app.us5.list-manage.com/subscribe/post?u=755d5319d7343bc5b4796329e&amp;id=34a61fff0b";

  return (
    <BrowserRouter>
      <Provider theme={theme}>
        <Relative pb={5} bg="#F9423A" style={{ color: "white", height: "10vh"}}>
          <Absolute zIndex={1} left={0} right={0} top={0}>
            <Flex is="header" p={3}>
              <NavLink fontSize={3}>
                <StyledLink to="/">NoGame</StyledLink>
              </NavLink>
              <NavLink ml="auto">
                <a href="https://achillrudolph.com/" style={{textDecoration: "none", color: "white"}}>Blog</a>
              </NavLink>
              <NavLink>
                <StyledLink to="/support">Support</StyledLink>
              </NavLink>
              <NavLink>
                <StyledLink to="/privacy">Privacy</StyledLink>
              </NavLink>
            </Flex>
          </Absolute>
        </Relative>

        <Route path="/" exact component={MainPage} />
        <Route path="/privacy" exact component={PrivacyScreen} />
        <Route path="/support" exact component={SupportScreen} />


        <Flex
          bg={"#F9423A"}
          color="white"
          is="footer"
          alignItems="center"
          p={3}
          style={{ height: "5vh" }}
        >
          {/* <NavLink children="Privacy" href="#" /> */}
          {/* <NavLink children="Link 2" href="#" /> */}
          <Small ml="auto">© NoGame, 2020</Small>
        </Flex>
      </Provider>
    </BrowserRouter>
  );
};

const StyledLink = styled(Link)`
  text-decoration: none;
  color: white;
`;
export default App;
