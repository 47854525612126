import React from "react";

const SupportScreen = props => (
  <div
    style={{
      padding: "13%",
      paddingTop: "4%",
      backgroundColor: "#F9423A",
      color: "white",
      height: "100vh"
    }}
  >
    <h2>Contact us</h2>
    <p>
      For feedback or requests please send an email to nogameapp@gmail.com - we love to hear from you.
    </p>
  </div>
);

export default SupportScreen;
